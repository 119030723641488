<template>
  <DashboardLayout
    :is-mobile-shop="isMobileShop"
    :show-navigation="true"
    :show-header-block="false"
    :title="$t('common.settings')"
  >
    <div>
      <div class="settings">
        <div class="container">
          <div class="settings__profile-block">
            <ImageInput
              :account="accountStore.values"
              :value="settingsStore.values.image || ''"
              :loading="settingsStore.loading"
              :show-clickable-text="isMobile"
              :clickable-text="$t('common.edit_photo')"
              @input="settingsStore.updateImage"
            />
          </div>

          <AccountSettingsForm
            :initial-values="settingsStore.values"
            :loading="settingsStore.loading"
            @submit="accountStore.updateData"
          />
          <PersonalSettingsForm
            :initial-values="settingsStore.values"
            :loading="settingsStore.loading"
            @submit="settingsStore.updateSettings"
          />
          <GeneralSettingsForm
            :initial-values="settingsStore.values"
            :loading="settingsStore.loading"
            @submit="settingsStore.updateSettings"
          />

          <div class="settings__wrap" :class="{ 'settings__open-change-password': props.openChangePassword && !changed }" ref="passwordSection">
            <div
              class="settings__item inline"
              :class="{ 'action-inline': change }"
            >
              <div class="settings__item-text">
                <div class="settings__item-text-name">
                  {{ $t("common.password") }}
                </div>
                <div class="settings__item-text-description" />
              </div>
              <div class="settings__item-setting">
                <span
                  v-if="change"
                  class="settings__item-setting__cancel"
                  @click="cancel"
                  v-text="$t('common.cancel_upper')"
                />
                <template v-else>
                  <PasswordField
                    @input="currentPassword = $event.target.value"
                    type="password"
                    :placeholder="$t('change_password.current_password')"
                  />
                  <span
                    class="settings__item-setting__info"
                    @click.prevent="openChangePassword"
                  >
                    {{ $t("common.change_password") }}
                  </span>
                </template>
              </div>
            </div>
            <div v-if="change" class="settings__wrap-confirm-password">
              <PasswordField
                @input="newPassword = $event.target.value"
                class="settings__item-setting__input"
                type="password"
                :placeholder="$t('change_password.new_password')"
              />
              <PasswordField
                @input="newPasswordConfirm = $event.target.value"
                class="settings__item-setting__input"
                type="password"
                :placeholder="$t('change_password.confirm_password')"
              />
              <button type="submit" name="save" @click.prevent="updatePassword">
                {{ $t("common.save") }}
              </button>
            </div>
          </div>

          <PositionSettingsForm
            :initial-values="settingsStore.values"
            :loading="settingsStore.loading"
            @submit="settingsStore.updateSettings"
          />
          <SecondDevice v-if="isMobile" class="second-device-wrapper" />
          <div class="settings__logout">
            <div class="settings__logout-button" @click="auth2Store.logout">
              <img src="../../../assets/icons/logout-red.svg" alt="logout" />
              {{ $t("common.logout") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <RecommendedCourseList />
  </DashboardLayout>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import RecommendedCourseList from "@/components/course/RecommendedCourseList.vue";
import PositionSettingsForm from "@/components/form/dashboard/PositionSettingsForm.vue";
import GeneralSettingsForm from "@/components/form/dashboard/GeneralSettingsForm.vue";
import PersonalSettingsForm from "@/components/form/dashboard/PersonalSettingsForm.vue";
import AccountSettingsForm from "@/components/form/dashboard/AccountSettingsForm.vue";
import ImageInput from "@/components/inputs/ImageInput.vue";
import backService from "@/plugins/service";
import i18n from "@/i18n";
import SecondDevice from "@/components/SecondDevice.vue";
import useIsMobile from "@/util/useIsMobile";
import DashboardLayout from "@/views/pages/dashboard/DashboardLayout.vue";
import { isShoppingEnabled } from "@/util/helpers";
import { useStores } from "@/store/use.store";
import { restoreLocalStorageJSON } from "@/plugins/misc";
import PasswordField from "@/components/form/PasswordField.vue";

const props = defineProps({
  openChangePassword: {
    type: Boolean,
    default: false,
  },
});

const { settingsStore, accountStore, auth2Store, alertStore } = useStores();
const { isMobile } = useIsMobile();

const change = ref(false);
const changed = ref(false);
const currentPassword = ref("");
const newPassword = ref("");
const newPasswordConfirm = ref("");

settingsStore.request();
accountStore.request();

const isMobileShop = computed(() => isMobile.value && isShoppingEnabled());

const passwordSection = ref(null);

const scrollToPassword = () => {
  if (passwordSection.value) {
    passwordSection.value.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

const getUsername = () => {
  const username = settingsStore.data?.username;
  if (username !== undefined) {
    return username;
  }

  return restoreLocalStorageJSON(settingsStore.LOCAL_STORAGE_KEY)?.username;
}

const cancel = () => {
  change.value = false;
  newPassword.value = newPasswordConfirm.value = "";
};
const openChangePassword = async () => {
  await backService
    .post("auth/password/check", {
      username: getUsername(),
      password: currentPassword.value,
    })
    .then(() => {
      change.value = true;
    })
    .catch(() => {
      alertStore.error(
          i18n.global.t("common.error"),
          i18n.global.t("change_password.wrong_password"),
      );
    });
};
const updatePassword = async () => {
  if (!newPassword.value || newPassword.value !== newPasswordConfirm.value) {
    alertStore.error(
        i18n.global.t("common.error"),
        i18n.global.t("change_password.passwords_not_match")
    );
    return;
  }

  await backService
    .post("auth/password/change", {
      username: getUsername(),
      newpassword: newPassword.value,
    })
    .then(() => {
      changed.value = true;
      alertStore.success(
          i18n.global.t("common.success"),
          i18n.global.t("change_password.password_changed"),
      );
      cancel();
    })
    .catch((error) => {
      const errors = error.response.data.newpassword ?? [];
      errors.forEach((err) => {
        alertStore.error(
            i18n.global.t("common.error"),
            err,
        );
      });
    });
};

onMounted(() => {
  if (props.openChangePassword) {
    setTimeout(function () {
      scrollToPassword();
    }, 100);
  }
});
</script>

<style lang="scss" scoped>
.spacer {
  padding: 10px;
}

#file_upload {
  display: none;
}

.settings {
  &__open-change-password {
    border: 2px solid $color-red;
    border-radius: 8px;
    transition: border 0.3s ease-in-out;
  }
  &__wrap {
    &-confirm-password {
      display: flex;
      gap: 26px 40px;
      padding: 0 20px;
      margin-bottom: 22px;
      margin-top: 10px;

      .form-group {
        height: 50px;
        margin-bottom: 0;

        :deep(.form-control) {
          margin-top: 0;
        }
      }

      button {
        display: block;
        background-color: $color-white;
        padding: 15px;
        width: 360px;
        height: 50px;
        border: 2px solid $color-primary;
        border-radius: 8px;
        color: $color-primary;
        font-family: $font-family;
        font-weight: 500;
        font-size: $font-size-md;
        cursor: pointer;
      }

      @media screen and (max-width: 1200px) {
        flex-wrap: wrap;

        .form-group > :deep(.form-control) {
          min-width: 300px;
        }

        button {
          min-width: 300px;
        }
      }

      @media screen and (max-width: $breakpoint-lg) {
        flex-wrap: wrap;
        padding: 8px 10px;
        gap: 0 40px;

        .form-group > :deep(.form-control) {
          min-width: 200px;
        }

        .settings__item-setting__input {
          margin-bottom: 10px;
        }

        button {
          min-width: 200px;
        }
      }

      @media screen and (max-width: $breakpoint-sm) {
        .form-group > :deep(.form-control) {
          min-width: 300px;
        }

        button {
          width: 300px;
          margin-top: 16px;
        }
      }
    }
  }

  &__upload-photo {
    display: block;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
    cursor: pointer;
  }

  &__item-setting {
    position: relative;

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }

    :deep(.form-group) {
      margin: 0;
    }

    :deep(.form-control) {
      margin: 0;
    }

    &__input {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-md;
      font-weight: 400;
      line-height: 15px;
    }

    &__cancel {
      color: $color-menu-item;
      font-family: $font-family;
      font-size: $font-size-sm;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;
      text-decoration: none;
      cursor: pointer;
    }

    &__info {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      color: $color-menu-item;
      font-family: $font-family;
      font-size: $font-size-sm;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__profile-name {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 16px;
  }

  &__profile-value {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    line-height: 15px;
  }

  &__profile-fullname {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 11px;

    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }

  &__profile-info {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  &__profile-change {
    a {
      color: $color-menu-item;
      font-family: $font-family;
      font-size: $font-size-xs;
      font-weight: 400;
      line-height: 10px;
      text-decoration: none;
    }

    &.desktop-action {
      display: none;

      @media screen and (min-width: 900px) {
        display: block;
      }
    }

    &.mobile-action {
      display: block;
      align-self: center;
      margin: 50px 0;

      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  }

  &__profile-image {
    text-align: center;

    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 64px;
    }
  }

  &__profile-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  &__profile-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
      gap: 26px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;

      text-decoration: none;
    }
  }

  &__profile-settings-block {
    flex: 1;

    @media screen and (max-width: 900px) {
      min-width: calc(50% - 20px);
    }
  }

  &__profile-select-second-device {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    line-height: 10px;
    text-decoration: none;
    border-bottom: 1px solid $color-light-grey;
    padding: 8px 20px;
    min-height: $header-height;
    justify-content: center;

    align-self: center;
    align-items: center;
    gap: 10px;

    &.desktop-action {
      display: none;

      @media screen and (min-width: 900px) {
        display: flex;
      }
    }
  }

  &__profile-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 26px 46px;
    padding-top: 26px;
    margin-bottom: 30px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-light-grey;
    padding: 8px 20px;
    gap: 16px;

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 10px;
    }

    &.inline {
      @media screen and (max-width: $breakpoint-sm) {
        padding: 20px;
      }
    }
  }

  &__item:not(.inline) {
    min-height: $header-height;
  }

  .action-inline {
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: unset;
    }
  }

  &__item-text-name {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 400;
    line-height: 22px;
  }

  &__item-text-description {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    line-height: 22px;
  }

  &__logout {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__logout-button {
    display: flex;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    color: $color-light-coral;

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 26px;
      background-color: $color-light-grey;
      border-radius: 8px;
    }
  }
}

.second-device-wrapper {
  padding: 26px 0;
  border-bottom: 1px solid $color-light-grey;
}

:deep(.password input) {
  border-width: 2px;
  min-width: 360px;

  &:focus {
    border-width: 2px;
  }
}
</style>
